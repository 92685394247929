import React from 'react';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';

import DeliveryMap from 'modules/userModule/components/DeliveryMap';
import { StyledPageWrapper } from 'common/styles';
import { customHistory, SessionStorage } from '../../../../common/utils';

const StoreFrontHeaderComponent = dynamic(() => import('common/components/StoreFrontHeader'));

const OrderDeliveryPage: React.FC = () => {
  const { t } = useTranslation('address');

  const handleOnBack = () => {
    SessionStorage.removeOrderNumber();
    customHistory.back();
  };

  return (
    <StyledPageWrapper>
      <StoreFrontHeaderComponent title={t('setDeliveryLocation')} onBack={handleOnBack} />
      <DeliveryMap />
    </StyledPageWrapper>
  );
};

export default OrderDeliveryPage;
